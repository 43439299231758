import { useService } from '@redtea/react-inversify';
import { OneOfTheme } from 'styles/themes';
import anodosLogoSrc from 'styles/themes/anodos/anodos-second-logo.png';
import { twMerge } from 'tailwind-merge';

import { ConfigService } from 'core/config/config.service';

import { ConstructionIcon } from 'components/icons';
import { H1 } from 'components/typography';

type MobileNotAvailableProps = {
  show?: boolean;
};

export const MobileNotAvailable = ({ show }: MobileNotAvailableProps) => {
  const { theme } = useService(ConfigService);

  return (
    <div
      className={twMerge(
        'flex flex-col justify-center items-center fixed top-0 left-0 bottom-0 right-0 bg-black/80 backdrop-filter backdrop-blur-[5px] z-50',
        !show && 'min-[900px]:hidden'
      )}
    >
      {getIconByTheme(theme)}
      <H1 className="leading-9 text-center mb-7">{getAppNameByTheme(theme)} mobile coming soon</H1>
    </div>
  );
};

const getAppNameByTheme = (theme: OneOfTheme) => {
  switch (theme) {
    case 'anodos':
      return 'AnoVault';

    default:
      return 'Valio';
  }
};

const getIconByTheme = (theme: OneOfTheme) => {
  switch (theme) {
    case 'anodos':
      return <img src={anodosLogoSrc} className="w-[45px] h-10 mb-2.5" />;

    default:
      return <ConstructionIcon className="text-[109px]" />;
  }
};
