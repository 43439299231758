import {
  IERC20,
  IERC20Metadata__factory,
  IERC20__factory,
  IERC712__factory,
  IERC5267__factory,
} from '@valioxyz/valio-contracts';
import { injectable } from 'inversify';

import { Address } from 'core/address';
import { ChainID } from 'core/chain';
import { NetworkProvider } from 'core/network/network.provider';

export type ContractAddress = string;

@injectable()
export class ContractStore {
  constructor(private readonly networkProvider: NetworkProvider) {}

  /**
   * Get ERC20 contract instance
   * @param address - contract address
   * @param useSigner - use signer or provider to connect to contract. For read-only operations use provider
   * for some cases using signer is required (e.g. when you need to call `approve` method)
   * when you need to check allowance or balance strictly use provider, or it will be crashing. (e.g. Ledger wallet does not support `eth_call` when using signer)
   * @returns ERC20 contract instance
   **/
  readonly getERC20Contract = (address: ContractAddress, useSigner?: boolean): IERC20 => {
    return IERC20__factory.connect(
      address,
      useSigner ? this.networkProvider.getSigner() : this.networkProvider.getProvider()
    );
  };

  readonly getDomain = async (
    tokenAddress: Address
  ): Promise<{
    name: string;
    version: string;
    chainId: number;
    verifyingContract: string;
    salt?: string;
  }> => {
    try {
      const domain = await IERC5267__factory.connect(
        tokenAddress,
        this.networkProvider.getProvider(false)
      ).eip712Domain();

      return {
        name: domain.name,
        version: domain.version,
        chainId: domain.chainId.toNumber() as ChainID,
        verifyingContract: domain.verifyingContract,
        salt: domain.salt,
      };
    } catch {
      const chainId = this.networkProvider.chainId;

      const version = await this.getVersion(tokenAddress);
      const name = await this.getName(tokenAddress);
      const verifyingContract = tokenAddress;

      return { name, version, chainId, verifyingContract };
    }
  };

  readonly getVersion = async (tokenAddress: Address): Promise<string> => {
    let version = '1';

    try {
      version = await IERC712__factory.connect(tokenAddress, this.networkProvider.getSigner(false)).version();

      return version;
    } catch {
      //
    }

    try {
      version = await IERC712__factory.connect(tokenAddress, this.networkProvider.getSigner(false)).ERC712_VERSION();

      return version;
    } catch {
      //
    }

    return version;
  };

  readonly getName = async (tokenAddress: Address): Promise<string> => {
    return await IERC20Metadata__factory.connect(tokenAddress, this.networkProvider.getSigner()).name();
  };
}
