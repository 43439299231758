import BigNumber from 'bignumber.js';
import { injectable } from 'inversify';
import { computed, makeObservable } from 'mobx';
import { Observable, merge } from 'rxjs';

import { Asset } from 'core/asset';
import { AssetsStore } from 'core/asset/assets.store';
import { ChainID, MainChainID, chains } from 'core/chain';
import { ConfigService } from 'core/config/config.service';
import { NetworkStore } from 'core/network/network.store';
import { Valio } from 'core/valio';

import { isSomething } from 'utils/is-something';

import { LazyWalletBalanceService } from './lazy-wallet-balance.service';
import { VaultBalanceService } from './vault-balance.service';
import { WalletBalanceService } from './wallet-balance.service';

@injectable()
export class BalanceService {
  vaultBalanceUpdated$: Observable<readonly [Asset, BigNumber]>;

  walletBalanceUpdated$: Observable<readonly [Asset, BigNumber]>;

  constructor(
    private readonly networkStore: NetworkStore,
    private readonly assetsStore: AssetsStore,
    private readonly configService: ConfigService
  ) {
    makeObservable(this);
    this.vaultBalanceUpdated$ = merge(
      ...Object.values(chains).map(
        (chain) => this.networkStore.networks[chain.chainId].get(VaultBalanceService).balanceUpdated$
      )
    );

    this.walletBalanceUpdated$ = merge(
      ...Object.values(chains).map(
        (chain) => this.networkStore.networks[chain.chainId].get(WalletBalanceService).balanceUpdated$
      )
    );
  }

  @computed
  get walletBalance(): BigNumber {
    return this.getWalletBalance(this.assetsStore.nativeAsset);
  }

  @computed
  get totalWalletBalance(): BigNumber {
    const chainsArr = Object.values(chains);

    return chainsArr
      .map((chain) => this.getChainWalletBalance(chain.chainId))
      .reduce((total, chainBalance) => {
        return total.plus(chainBalance);
      }, BigNumber(0));
  }

  @computed
  get nativeAssetsBalance(): BigNumber {
    const chainsArr = Object.values(chains);

    return chainsArr
      .flatMap((chain) => this.assetsStore.getNativeAsset(chain.chainId))
      .map((item) => this.getWalletBalance(item))
      .reduce((total, chainBalance) => {
        return total.plus(chainBalance);
      }, BigNumber(0));
  }

  @computed
  get allAssetsWithBalance(): Asset[] {
    return Object.values(chains)
      .map((chain) => this.getAssetsByChainWithBalance(chain.chainId))
      .flat();
  }

  @computed
  get maxAssetsAllowedAllChains(): Valio.MaxActiveAssets {
    const isStageOrTestEnv = this.configService.isStageOrTestEnv;

    return {
      [ChainID.Ethereum]: 4,
      [ChainID.Arbitrum]: isStageOrTestEnv ? 14 : 8,
      [ChainID.Optimism]: isStageOrTestEnv ? 14 : 8,
    } as Valio.MaxActiveAssets;
  }

  @computed
  get optimalRelayFeeAsset(): Asset {
    const chainId = MainChainID;

    const tokens = Valio.depositSymbols[chainId]
      .map((symbol) => this.assetsStore.getAssetBySymbol(symbol, chainId))
      .filter(isSomething)
      .map((asset) => {
        const balance = this.getWalletBalance(asset);

        return { asset, balance };
      });

    const assetWithBalance = tokens.reduce((max, current) => {
      return current.balance.gt(max.balance) ? current : max;
    }, tokens[0]);

    return assetWithBalance.asset;
  }

  @computed
  get depositTokens(): Asset[] {
    const chainId = MainChainID;

    const tokens = Valio.depositSymbols[chainId]
      .map((symbol) => this.assetsStore.getAssetBySymbol(symbol, chainId))
      .filter(isSomething)
      .sort((a, b) => {
        const balanceA = this.getWalletBalance(a);
        const balanceB = this.getWalletBalance(b);

        return balanceB.minus(balanceA).toNumber();
      });

    const assetsByChainWithBalance = this.getVaultAssetsByChainWithBalance(chainId, true);
    const maxActiveAssets = this.maxAssetsAllowedAllChains[chainId];

    if (assetsByChainWithBalance.length < maxActiveAssets) return tokens;

    const depositTokenAddresses = tokens.map((token) => token.address);

    return assetsByChainWithBalance.filter((asset) => depositTokenAddresses.includes(asset.address));
  }

  getVaultAssetsByChainWithBalance = (chainId: ChainID, includeNative = false): Asset[] => {
    return this.assetsStore
      .getAssetsByChain(chainId, includeNative)
      .filter((asset) => !this.getVaultBalance(asset).isZero());
  };

  getAssetsByChainWithBalance = (chainId: ChainID, includeNative = false): Asset[] => {
    return this.assetsStore.getAssetsByChain(chainId, includeNative).filter((asset) => {
      const usdValue = asset.toUSD(this.getLazyWalletBalance(asset));

      return usdValue.gte(1);
    });
  };

  getChainWalletBalance = (chainId: ChainID): BigNumber => {
    const assets = this.getAssetsByChainWithBalance(chainId);

    return assets.reduce((total, asset) => {
      const assetAmountInUSD = BigNumber(asset.toUSD(this.getLazyWalletBalance(asset)));

      return total.plus(assetAmountInUSD);
    }, BigNumber(0));
  };

  getWalletBalance = (asset: Asset): BigNumber =>
    this.networkStore.networks[asset.chain.chainId].get(WalletBalanceService).getBalanceOf(asset);

  getVaultBalance = (asset: Asset): BigNumber => {
    return this.networkStore.networks[asset.chain.chainId].get(VaultBalanceService).getBalanceOf(asset);
  };

  getLazyWalletBalance = (asset: Asset): BigNumber => {
    return this.networkStore.networks[asset.chain.chainId].get(LazyWalletBalanceService).getBalanceOf(asset);
  };
}
