import { injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { Observable, Subject } from 'rxjs';

import { Collection } from 'utils/collection';
import { Disposable } from 'utils/disposable';

import { ModalLike, createModal } from './create-modal';

export enum ModalCloseBehavior {
  Close = 'close',
  CloseAll = 'close-all',
}

export type ModalMeta = {
  permanent?: boolean;
  unwrapped?: boolean;
  hideCloseIcon?: boolean;
  /**
   * Default: Close
   */
  closeBehavior?: ModalCloseBehavior;
};

export type Modal<P extends object = object> = {
  component: ModalLike<P>;
  props?: P;
  meta?: ModalMeta;
  idx: number;
};

type OpenParams<P extends object> = {
  props?: P;
  meta?: ModalMeta;
};

@injectable()
export class ModalsStore extends Disposable {
  @observable.shallow
  items: Modal[] = [];

  closeModal$: Observable<Modal>;

  openModal$: Observable<Modal>;

  private idx = 0;

  private closeModal$$ = new Subject<Modal>();

  private openModal$$ = new Subject<Modal>();

  constructor() {
    super();
    makeObservable(this);

    this.closeModal$ = this.closeModal$$.asObservable();

    this.openModal$ = this.openModal$$.asObservable();

    this.autoDispose(this.openModal$$.subscribe(this.pushModal));
    this.autoDispose(this.closeModal$$.subscribe(this.popModal));
  }

  @computed
  get active(): Modal | undefined {
    return Collection.takeLast(this.items);
  }

  @computed
  get hasPermanent(): boolean {
    return this.items.some(({ meta }) => meta?.permanent);
  }

  @action
  private pushModal = (modal: Modal): void => {
    const idx = this.items.findIndex((item) => item.component.displayName === modal.component.displayName);

    if (idx !== -1) {
      this.items[idx] = {
        ...this.items[idx],
        meta: {
          ...this.items[idx].meta,
          ...modal.meta,
          permanent: this.items[idx].meta?.permanent || modal.meta?.permanent,
          hideCloseIcon: this.items[idx].meta?.hideCloseIcon || modal.meta?.hideCloseIcon,
        },
      };
    } else {
      this.items.push(modal);
    }
  };

  @action
  private popModal = (modal: Modal): void => {
    this.items = this.items.filter(({ component }) => component.displayName !== modal.component.displayName);
  };

  /**
   * Closes active modal
   */
  close = (displayName: string): void => {
    const closed = this.items.find((item) => item.component.displayName === displayName);

    if (!closed) return;

    this.closeModal$$.next(closed);
  };

  /**
   * Closes all modals
   */
  closeAll = (): void => {
    this.items.forEach((item) => this.closeModal$$.next(item));
  };

  open = <P extends object = Record<string, unknown>>(component: ModalLike<P>, params?: OpenParams<P>): void => {
    this.openModal$$.next({
      component: createModal<P>(component, params?.props),
      meta: params?.meta,
      props: params?.props,
      idx: ++this.idx,
    });
  };

  handleClose = (): void => {
    if (!this.active) return;

    if (this.active.meta?.closeBehavior === ModalCloseBehavior.CloseAll) {
      this.closeAll();
    } else {
      this.close(this.active.component.displayName);
    }
  };
}
